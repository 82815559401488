
import { Order, OrderTypes } from "@/store/modules/order/order.types";
import { DefaultState, Pagination } from "@/types/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { commaDate, currency, pad, slash } from "@/filters/utils";

const order = namespace("Order");

@Component({
  components: {
    NoData: () => import("@/components/utils/NoData.vue"),
  },
  filters: {
    currency,
    slash,
    commaDate,
    pad,
  },
})
export default class OrdersView extends Vue {
  @Prop({ default: true }) pagination?: boolean;
  @Prop({ default: "" }) query?: string;

  @order.State("orders")
  public orders!: Order[];

  @order.State("add")
  public addOrder!: DefaultState;

  @order.State("pages")
  public pageCount!: number;

  @order.State("loadingOrder")
  public loadingOrders!: boolean;

  @order.Mutation(OrderTypes.SET_ADD_ORDER_DIALOG)
  public setAddOrder!: (addOrder: boolean) => void;

  @order.Action(OrderTypes.LOAD_ORDERS)
  public loadOrders!: (pagination: Pagination) => void;

  public page = 1;

  @Prop({ required: true, default: 10 }) public pageSize!: number;

  public headers = [
    {
      text: "ID",
      align: "left",
      sortable: true,
      value: "id",
      width: 150,
    },
    {
      text: "Amount",
      align: "left",
      sortable: true,
      value: "amount",
    },
    {
      text: "Items",
      align: "left",
      sortable: true,
      value: "items",
    },
    {
      text: "Farmer",
      align: "left",
      sortable: true,
      value: "farmer",
    },
    {
      text: "Agent",
      align: "left",
      sortable: true,
      value: "agent",
    },
    {
      text: "District",
      align: "left",
      sortable: true,
      value: "agent.district",
    },
    {
      text: "Date",
      align: "left",
      sortable: true,
      value: "created_at",
    },
    {
      text: "",
      sortable: false,
      value: "actions",
    },
  ];

  closeAddOrderDialog(): void {
    this.setAddOrder(false);
  }

  openAddOrderDialog(): void {
    this.setAddOrder(true);
  }

  @Watch("pageSize")
  handlePageSizeChange(): void {
    this.page = 1;
    this.queryOrders();
  }

  @Watch("page")
  handlePageChange(): void {
    this.queryOrders();
  }

  queryOrders(): void {
    this.loadOrders({
      page: this.page,
      limit: this.pageSize,
      query: this.query,
    });
  }

  mounted(): void {
    if (this.orders.length == 0) this.queryOrders();
  }
}
